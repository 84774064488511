import React, { useRef } from "react";
import styled from "styled-components";
import SlideShow from "../../components/framework/SlideShow/slideShow";
import ThemeList, { themeListDataType } from "../../components/framework/ThemeList/themeList";
import PageWrapper from "../../components/PageWrapper/pageWrapper";
import ParagraphText from "../../components/framework/ParagraphText/paragraphText"
import { useEffect } from 'react';
import { KiService } from '../../common/api/Kulturio/KiService';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { zoneType } from '../../common/types/types';
import { useContext } from "react";
import { Store } from "../../components/Store/store";
import HeadingContainer from "../../components/modules/HeadingContainer/headingContainer";
import PageLoader from "../../components/framework/PageLoader/pageLoader";
import { shortLangCode } from "../../common/helpers/helpers";
import VideoList from "../../components/framework/VideoList/videoList";
import { Helmet } from "react-helmet";


const RoomPageContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-left: var(--marginLeftSm);
    margin-right: var(--marginRightSm);
    margin-top: var(--marginTopSm);
    @media only screen and (min-width: 1024px){
        margin-left: var(--marginLeftLg);
        margin-right: var(--marginRightLg);
        margin-top: var(--marginTopLg);
    }
`;

const SubHeading = styled.p`
    font-weight: 400;
    color: var(--colorSecondary);
    text-transform: uppercase;
    font-size: 0.875rem;
    line-height: 1.0625rem;
    margin: 0;
`;

const Heading2 = styled.h2`
    font-weight: 700;
    color: var(--colorPrimary);
    font-size: 1.5625rem;
    line-height: 1.9375rem;
    margin: 0;
    margin-top: 0.5em;
`;

const Paragraph = styled.p`
    font-size: 0.875rem;    
    font-weight: 400;
    color: var(--colorPrimary);
    
    @media only screen and (min-width: 1024px){
        font-size: 1rem;
    }
`;

const EndMargin = styled.div`
    padding-bottom: 60px;
`;

const Citation = styled.div`
`;


const RoomPage = ({ params}) => {
    //console.log("pageContext: ", pageContext);
    //console.log("params: ",params);
    const { state, dispatch } = useContext(Store);
    const roomId = { room_id: "room_id" in params ? params.room_id : "" };
    const pageUrl = new URL(`${state.constants.siteUrl}/room/${roomId.room_id}`);
    const { t, i18n } = useTranslation("common");
    const [stopReaderAudio, setStopReaderAudio] = useState(false);
    const [data, setData] = useState([])
    const [displays, setDisplays] = useState([]);
    const setDisplaysRef = useRef(setDisplays);
    const setDataRef = useRef(setData)
    const [errorState, setErrorState] = useState(false);
    const setErrorStateRef = useRef(setErrorState);
    
    const videos:any = data.length && data[0]?.content?.videos && data[0]?.content?.videos?.videoList?.length  ?  {title: data[0]?.content?.videos.title, videosList: data[0]?.content?.videos.videoList}: [];
    
    if(videos && data[0]?.content?.videos?.videoList){
        videos.videoList = data[0]?.content?.videos?.videoList?.slice().filter(v_el=>{return v_el.language === "both" ||v_el.language === shortLangCode(i18n.language) || !v_el.language})
    }
    const slideshowsUuidRef = useRef("");


    let _themeListData: themeListDataType[];
    //console.log("displays before themeListData: ", displays);
    if (displays.every(el => el === undefined) && displays.length) {
        //console.log("All elements are undefined!!!!!")
        _themeListData = [{ title: t("error.noDataError"), numArtifacts: 0, routeUrl: "refresh", thumbs: [] }];

    } else {
        _themeListData = displays.map((elem) => {
            const title: string = elem.content.title[`locale:${shortLangCode(i18n.language)}`];
            const routeUrl: string = `/theme/${roomId.room_id.toString()}_${elem.id}`;
            const thumbs: string[] = ["", "", ""].map((_, idx) => {
                let artifactImageUrl: string;
                try {
                    artifactImageUrl = elem.content.tabs[0].artifacts[idx].reference.imageUrl;
                }
                catch (err) {
                    console.log("No artifact found in index:", idx, ".  "/*, err*/)
                    artifactImageUrl = "";
                }
                return artifactImageUrl;
            });
            const singleThumb: string = elem.content?.image?.media?.imageUrl;

            const numArtifacts = elem.content.tabs ? elem.content.tabs.reduce((acc, tabEl) => {
                return acc + tabEl.artifacts?.length;
            }, 0) : 0;

            return { title, numArtifacts, routeUrl, thumbs, singleThumb }
        });
    }

    const themeListData = _themeListData;

    const imageUrl = data.length > 0 && data[0]?.content?.image?.media.imageUrl;

    const getAudioUrl = () => {
        let _audioUrl;
        try {
            _audioUrl = data.length > 0 && (shortLangCode(i18n.language) === "no" ? data[0].content.audio.norwegian.media.resources.mp3.url : data[0].content.audio.english.media.resources.mp3.url);
        }
        catch (err) {
            //console.log(err);
            _audioUrl = undefined;
        }
        finally {
            return _audioUrl;
        }
    }

    const audioUrl = getAudioUrl();



    const getQuoteAudioUrl = () => {
        let _audioUrl;
        try {
            _audioUrl = data.length > 0 && (shortLangCode(i18n.language) === "no" ? data[0].content.citation.audio.norwegian.media.resources.mp3.url : data[0].content.citation.audio.english.media.resources.mp3.url);
        }
        catch (err) {
            //console.log(err);
            _audioUrl = undefined;
        }
        finally {
            return _audioUrl;
        }
    }

    const quoteAudioUrl = getQuoteAudioUrl();

    useEffect(() => {
        let isAlive = true;
        if (state.data.zones.length < 1) return () => (isAlive = false);
        if (displays.length /*&& (slideShowImages.length || !slideshowsUuid)*/) return () => (isAlive = false);
        

        const api = new KiService();
        const zoneTmp: zoneType = roomId.room_id.toString() as zoneType;

        const zones = state.data.zones;
        let _currentZone;
        if (zones.every(el => el === undefined)) {
            console.error("Error: No zone data found. Please check connection and refresh browser!");
            _currentZone = -1;
            setErrorStateRef.current(true);
        } else {
            _currentZone = zones.find((el) => el.content.zone.toString() === zoneTmp);
        }
        const currentZone = _currentZone;

        
            

        if (isAlive) {
            setDataRef.current([currentZone]);
            if(currentZone.content?.slideshows && currentZone.content?.slideshows[0]?.reference?.uniqueId){
                slideshowsUuidRef.current = currentZone.content.slideshows[0].reference.uniqueId;
                
           
            }
            

        }
        let ids;
        try {
            ids = currentZone.content.displays.map((elem) => {
                return elem.reference.uniqueId;
            });
        }
        catch (err) {
            console.log("ERROR: missing key displays in zone: ", zoneTmp/*, "  Detailed Error: ", err*/);
            ids = [];
        }

        // get displays data
        const _readMoreDataFetch = ids.map(async (rmId) => {
            let response;
            try {
                response = api.getByUuid(rmId)
            }
            catch (err) {
                console.error(err);
                response = {};
            }
            finally {
                return response;
            }

        });


        
        
        

        Promise.all(_readMoreDataFetch).then((res) => {
            const _readMoreData = res.map((rmEl) => {
                return rmEl;
            });
            if (isAlive) {
                //console.log(isAlive)
                setDisplaysRef.current(_readMoreData);
            }
        });


        return () => (isAlive = false)

    }, [state.data.zones])


    return (
        
        
        <PageWrapper
            pageTitle={data.length ? data[0].content?.title[`locale:${shortLangCode(i18n.language)}`] + " - Tidsrom" : "Tidsrom"}
            pageUrl={pageUrl}
            description={data.length && data[0].content?.body[`locale:${shortLangCode(i18n.language)}`]}
            image={imageUrl}
        >   
          
            
            <RoomPageContainer>
                
            {
                data.length > 0 && themeListData.length > 0 ?
                    <>

                        <SlideShow
                            //showShadow={true}
                            imageObjectFit={"contain"}
                            backgroundColor={"#DCD1C0"}
                            clickToFullscreen={true}
                            imagesList={imageUrl ? [imageUrl] : []}
                            fullHeight />
                        <HeadingContainer soundUrl={audioUrl} stopReaderAudio={stopReaderAudio} setStopReaderAudio={setStopReaderAudio}>
                            {data[0].content?.title[`locale:${shortLangCode(i18n.language)}`]}
                        </HeadingContainer>
                        <SubHeading data-testid="zoneSubheading">{data[0].content?.zone !== undefined ? `${t("general.room")} ${data[0].content?.zone}` : t("error.noDataError")}</SubHeading>
                        <ParagraphText text={data[0].content?.body[`locale:${shortLangCode(i18n.language)}`]}
                        />

                        {themeListData.length > 0 && <Heading2>{t("general.exploreTheme")}</Heading2>}
                        <ThemeList data={themeListData}></ThemeList>
                        {/*console.log("Language code video: ",shortLangCode(i18n.language))*/}
                        {/*console.log("VIDEOS: ", videos)*/}
                        {/*console.log("videos?.videoList?.length > 0: ", videos?.videoList?.length > 0)*/}
                        {videos?.videoList?.length > 0 &&
                        <>
                        {videos?.videoList?.length > 0 && <Heading2>{videos.title[`locale:${shortLangCode(i18n.language)}`]/*t("general.exploreFilm")*/}</Heading2>}
                        <VideoList
                            data={videos.videoList}
                            setStopReaderAudio={setStopReaderAudio}
                            slideshowUuid= {slideshowsUuidRef.current}
                        ></VideoList>
                        </>}
                        {data.length && Object.keys(data[0].content?.citation?.title).length > 0 &&
                            <Citation>
                                <HeadingContainer headerType="h2" soundUrl={quoteAudioUrl} stopReaderAudio={stopReaderAudio} setStopReaderAudio={setStopReaderAudio} >
                                    {data[0].content?.citation?.title[`locale:${shortLangCode(i18n.language)}`]}
                                </HeadingContainer>
                                <ParagraphText numChars={200} text={data[0].content?.citation?.body[`locale:${shortLangCode(i18n.language)}`]}
                                />

                            </Citation>
                            
                        }
                        
                        </>
                    
                    : <PageLoader showError={errorState}/>
            }
            <EndMargin />
            </RoomPageContainer>

        </PageWrapper>
        
    )
}

export default RoomPage;