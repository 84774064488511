import styled from 'styled-components';
import React from "react";

const Container = styled.div`
  .SVG_ArrowFill{
    fill: #454f63
  }
`;

const IconArrowRight = () => {
    return(
        <Container>
            <svg xmlns="http://www.w3.org/2000/svg" width="8" height="13" viewBox="0 0 8 13">
                <defs>
                </defs>
                <path className="SVG_ArrowFill" d="M0,.788.863,0,8,6.5.863,13,0,12.216,6.271,6.5Z"/>
            </svg>
        </Container>
    );
};

export default IconArrowRight;