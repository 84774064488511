import { navigate } from "gatsby";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import IconArrowRight from "../../modules/IconArrowRight/iconarrowright";
import placeholderImage from "../../../images/placeholders/placeholder.png";

const Container = styled.ul`
    
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    padding-top: 20px;    

    // list overrides:
    list-style: none;
    margin-block-start: 0em;
    margin-block-end: 0em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 0px;    
`;

const ThumbRow = styled.div`
    display: flex; 
    align-items: center;   
    margin-left: 8px;    
`;

const ThumbContainer = styled.div<ThumbContainerProps>`
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;     
    --totalWidth: 32px;
    --thumbHeight: 22px;
    --thumbWidth: 19.21px;
    height: 16px;//var(--thumbHeight);
    width: 13px;//var(--thumbWidth);    
    object-fit: contain;    
    margin: 0;    
    z-index: ${props => props.zIndex};    

    &:not(:first-of-type):not(:last-of-type) {
        margin:  13px;
    }

    &::before{
        display: block;
        position: absolute;        
        background-color: #FFF;// #f1f1f1;
        content: "";
        width: 32px;
        height: 32px;       
        border-radius: 50%;  
        z-index: -1;  
        border: 1px solid #FFF;
    }
    
    &::after{
        display: block;
        position: absolute;      
        content: "";
        width: 32px;
        height: 32px;       
        border-radius: 50%;                       
        background-color: rgba(0,0,0,0.05);
    }   
    
`;
const SingleThumbImage = styled.img`
    width: 67px;
    max-height: 35px;
    object-fit: contain;
    //border: 1px solid green;
    margin-left: -2px;
`;


const ThumbImage = styled.img`
    --thumbHeight: 22px;
    --thumbWidth: 19.21px;
    object-fit: contain;
    max-height: 22px;
    max-width: 19.21px;  
    max-height: var(--thumbHeight);
    max-width: var(--thumbWidth);     
`;

const ImageTextContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: calc(2.5em + 8px);
    align-items: flex-start;
    text-align: left;
    @media only screen and (min-width: 1024px){
        margin-left: calc(2.5em + 8px);
    }    
`;

const ImageTitle = styled.h4`
    font-weight: 400;
    font-size: 1.125rem;
    color: #000;        
    letter-spacing: 0.36px;
    line-height: 1.6875rem;
    margin: 0;    
`;

const ImageSubTitle = styled.h5`
    font-weight: 400;
    font-size: 0.875rem;
    color: #000;
    margin: 0;
    line-height: 1.0625rem;
    letter-spacing: 0px;
    opacity: 0.5;
`;


const ArrowContainer = styled.div`
    align-self: center;
    margin-left: auto;    
`;

const ListItem = styled.li`
    &:not(:last-of-type) {
        margin-bottom: 16px;        
    }    
`;

const HorizontalLine = styled.div`
    display: block;
    width: calc(100% - 5px);
    height: 1px;
    background-color: var(--colorBgGray);    
    margin-top: 15px;
`;

const ElementContainer = styled.button<ElementContainerProps>`
    width: 100%;
    display: flex;
    flex-direction: row; 
    align-items: center;
    padding: 0;
    background: none;
    border: none;
    cursor: pointer;

    &:hover ${ArrowContainer} {
            opacity: 0.5;
    }   

    &:hover ${ImageTitle} {
        text-decoration: underline;
    }
`;


export interface themeListDataType {
    title: string;
    numArtifacts: number;
    routeUrl: string;
    thumbs: string[];
    singleThumb?: string;
}


interface ThemeListProps {
    data: Array<themeListDataType>;
}

interface ThumbContainerProps {
    zIndex: number;
}

interface ElementContainerProps { }


const ThemeList: React.FC<ThemeListProps> = ({ data }) => {
    const { t } = useTranslation("common");
    //const Router = useRouter();

    const handleClick = (url: string) => {
        if(url === "refresh"){
            open("/", "_self");
        }
        else{
            navigate(url);
        }
        //Router.push(url);
    }

    return (
        <Container>
            {data.map((elem, idx) =>
                <ListItem
                    data-testid="ThemeListListItem"
                    key={elem.title + "_" + idx}>
                    <ElementContainer

                        tabIndex={0}
                        onClick={() => { handleClick(elem.routeUrl) }}
                    >
                        <ThumbRow>
                            {!elem.thumbs.every(eel=>eel==="") ? elem.thumbs.map((thumbUrl, tidx) =>
                                <ThumbContainer 
                                    key={thumbUrl + "_" + tidx + "_" + elem.title + "_" + idx}
                                    zIndex={elem.thumbs.length - tidx}
                                    /*src={thumbUrl ? thumbUrl + "&dimension=167x167" : placeholderImage}*/
                                >
                                    {/*<ThumbImageOverlay>*/}
                                        <ThumbImage
                                            src={thumbUrl ? thumbUrl + "&dimension=167x167" : placeholderImage} />
                                    {/*</ThumbImageOverlay>*/}
                                </ThumbContainer>
                            ) : <SingleThumbImage src={elem.singleThumb ? elem.singleThumb+ "&dimension=167x167" : placeholderImage}/>
                            }

                        </ThumbRow>
                        <ImageTextContainer>
                            <ImageTitle data-testid="themeListImageTitle">{elem.title}</ImageTitle>
                             <ImageSubTitle data-testid="themeListImageSubTitle">{elem.numArtifacts ? `${elem.numArtifacts} ${ t("general.artifacts")}` : t("general.text")}</ImageSubTitle> 
                        </ImageTextContainer>
                        <ArrowContainer><IconArrowRight /></ArrowContainer>
                    </ElementContainer>
                    <HorizontalLine/>
                </ListItem>)
            }


        </Container >
    );
}

export default ThemeList;