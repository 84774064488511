import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { KiService } from "../../../common/api/Kulturio/KiService";
import { shortLangCode } from "../../../common/helpers/helpers";
import Modal from "../Modal/modal";

import 'react-photoswipe/lib/photoswipe.css';
import { PhotoSwipe } from 'react-photoswipe';

const Container = styled.div`
    padding-top: 20px;
`;

const VList = styled.ul`
    list-style: none;

    // list override
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;
    padding-inline-start: 0;
`;

const VItem = styled.li<VItemProps>`  
    display: ${props => props.displayOn === "mobile" || !props.displayOn ? "flex" : "none"};
    &:not(:last-of-type){
        margin-bottom: 20px;
    }
    @media only screen and (min-width: 1024px){
        display: ${props => props.displayOn === "desktop" || !props.displayOn ? "flex" : "none"};
    }   
    
`;

const VButton = styled.button`
    display: flex;
    align-items: flex-start;
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
    width: 100%;   
    
    
    
`;

const VThumb = styled.img`
    width: 81px;
    object-fit: contain;
`;

const VTextContainer = styled.div`    

    display: flex;
    flex-direction: column;
    margin-left: 2.5em;
    align-items: flex-start;
    text-align: left;
    @media only screen and (min-width: 1024px){
        margin-left: 2.5em;
    }    
`;

const VTitle = styled.h4`
    font-weight: 400;
    font-size: 1.125rem;
    color: #000;        
    letter-spacing: 0.36px;
    line-height: 1.6875rem;
    margin: 0;

    &:hover{
        text-decoration: underline;
    }
`;

const VSubTitle = styled.h5`
    font-weight: 400;
    font-size: 0.875rem;
    color: #000;
    margin: 0;
    line-height: 1.0625rem;
    letter-spacing: 0px;
    opacity: 0.5;
`;
interface VItemProps {
    displayOn?: string;
}

interface VideoListProps {
    data: any;
    setStopReaderAudio: React.Dispatch<React.SetStateAction<boolean>>;
    slideshowUuid?: string;
}

const VideoList: React.FC<VideoListProps> = ({ data, setStopReaderAudio, slideshowUuid }) => {
    const { t, i18n } = useTranslation("common");
    const [modalOpen, setModalOpen] = useState(false);
    const [showVideoNr, setShowVideoNr] = useState(0);
    const [slideshowData, setSlideshowData] = useState({});
    const setSlideshowDataRef = useRef(setSlideshowData);
    //console.log("VideoList: ", data);
    
    const photoswipeOptions = {
        shareEl: false,
        zoomEl: true,
        maxSpreadZoom: 3,
        
    };
    const [isPhotoswipeOpen, setIsPhotoswipeOpen] = useState(false);

    const photoswipeCloseHandler = () => {
        setIsPhotoswipeOpen(false);
    }

    const photoswipeOpenHandler = () => {
        //@ts-ignore
        typeof window !== "undefined" && typeof window.gtag !== "undefined" &&
            //@ts-ignore
            window.gtag('event', "slideshow_opened", {
                    'event_category': "user_interaction"                     
            });
        setIsPhotoswipeOpen(true);
    }

    const handleClick = (idx) => {
        setStopReaderAudio(true);
        setShowVideoNr(idx);
        setModalOpen(true);
        //@ts-ignore
        typeof window !== "undefined" && typeof window.gtag !== "undefined" &&
        //@ts-ignore
        window.gtag('event', "video_opened", {
                'event_category': "user_interaction"                     
        });
    }

    useEffect(() => {
        if (!slideshowUuid) {
            return;
        }

        const api = new KiService();



        const getSlideShowData = async () => {

            try {
                const response: any = await api.getByUuid(slideshowUuid);
                const _slideshowData = {
                    image: response.imageUrl,
                    title: response.content?.title,
                    subTitle: response.content?.description,
                    imageList: response.content?.images?.map((elem, idx) => {
                        return {
                            src: elem.media?.resources?.jpeg?.url,
                            w: 800,
                            h: 800,
                            title: elem.media?.content?.description ? elem.media.content.description[`locale:${shortLangCode(i18n.language)}`] : ""
                        }
                    })


                }
                setSlideshowDataRef.current(_slideshowData);
                //console.log(_slideshowData);

            }
            catch (err) {
                console.error(err);
                //response = {};
            }
        }
        //console.log("calling getSlideShowData()")
        getSlideShowData();
    }, [slideshowUuid])

    return (
        <>
            {modalOpen && <Modal
                setModalOpen={setModalOpen}
                data={[data[showVideoNr]]}
                documentType={"fullscreen"}
                isVideo={true}

            >
                
            </Modal>}

            {/*console.log("slideshowUuid: ",slideshowUuid)*/}
                {slideshowUuid && slideshowData.imageList?.length > 0 && <PhotoSwipe isOpen={isPhotoswipeOpen} items={Array.from(slideshowData.imageList)} options={photoswipeOptions} onClose={photoswipeCloseHandler} />}
            <Container>
                <VList>
                    {data.map((el, idx) =>
                        <VItem 
                        key={"video_"+idx}
                        displayOn={el?.content?.displayOn}>
                            <VButton
                                onClick={() => { handleClick(idx) }}
                            >
                                <VThumb src={el.media.imageUrl} />
                                <VTextContainer>
                                    <VTitle>{el?.media?.content?.title ? el.media.content.title[`locale:${shortLangCode(i18n.language)}`] : ""}</VTitle>
                                    <VSubTitle>{el?.media?.content?.description ? el.media.content.description[`locale:${shortLangCode(i18n.language)}`] : ""}</VSubTitle>
                                </VTextContainer>
                            </VButton>
                        </VItem>
                    )}
                    
                    {slideshowUuid && slideshowData.imageList?.length > 0 && 
                    
                    <VItem key={"slideshow"}>
                            <VButton
                                onClick={()=> {photoswipeOpenHandler()} }
                            >
                                <VThumb src={slideshowData.image} />
                                <VTextContainer>
                                    <VTitle>{slideshowData.title ? slideshowData.title[`locale:${shortLangCode(i18n.language)}`] : ""}</VTitle>
                                   <VSubTitle>{slideshowData.subTitle ? slideshowData.subTitle[`locale:${shortLangCode(i18n.language)}`] : ""}</VSubTitle>
                                </VTextContainer>
                            </VButton>
                        </VItem>
                        }
                </VList>
            </Container>
        </>
    );
}

export default VideoList;